$primary-color: white;

.message-list {
    display: flex;
    flex-direction: column;
    gap: 13px;
    overflow: scroll;
    padding: 15px;
}

.button-container {
    margin-top: auto;;
}

.widget-container {
    background-color: white;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    position: relative;
}

.widget-hide {
    transform: translateY(1800px);
    opacity: 0.5;
  }
  
.widget {
    position: fixed;
    right: var(--ik-widget-right);
    left: var(--ik-widget-left);
    bottom: 30px;
    width: 360px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(95vh - var(--ik-widget-bottom-offset));
    max-height: 700px;

    form {
      margin-bottom: 0;
    }
  
    label,
    input,
    textarea {
      display: block;
      width: 100%;
      font-size: 14px;
    }
  
    label {
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    input,
    button,
    select,
    textarea {
      padding: 5px;
      line-height: 1.5;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  
    input,
    select {
      margin-bottom: 10px;
    }
  
    textarea {
      margin: 10px 0;
      resize: none;
    }
  
    .phoneInput {
      width: 100%;
    }
  
    transition: transform 0.5s ease-in-out 0s,
    opacity 0.5s;
}

@media (max-width: 576px) { 
    .widget {
      width: 90vw;
      height: 90vh;
      top: calc(10vh / 2);
      bottom: unset;
      right: unset;
      left: calc(10vw / 2);
    }
}

  
  .widget__submit {
    display: flex;
    justify-content: center;
  }


.widget__submit__button {
    cursor: pointer;
    color: $primary-color;
    font-size: 14px;
    background-color: var(--ik-widget-bg-color);
    width: 230px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
  }
  
  
  .widget__spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid var(--ik-widget-bg-color);
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 2s linear infinite;
    margin: 2px 5px;
  
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
  
      100% {
        transform: rotate(360deg);
      }
    }
  }
  
  .success {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    font-size: 20px;
  }
  
  .loader {
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top: 2px solid #FFFFFF;
      width: 15px;
      height: 15px;
      animation: spin 1s linear infinite;
  }
  

  .error {
    font-size: 14px;
    color: darkred;
    padding: 0px 25px;
    text-align: center;
  }