
.location-list {
    border-radius: 19px;
    background-color: white;
    min-height: 150px;
    margin-bottom: 10px;
    margin: 12px;
}

.list-item {
    display: flex;
    gap: 8px;
    flex-direction: row;
    background: white;
    padding: 12px 10px 16px;
    cursor: pointer;
    border-bottom: 1px solid lightgray;
}

.list-item-end {
    border-bottom: none;
    border-radius: 10px;
}

.list-item:hover {
    background: #F3F3F3;
}

.list-item .title {
    margin-bottom: 6px;
}

.list-item .address {
    font-weight: lighter;
    display: flex;
    flex-direction: column;
    color: gray;
    line-height: 15px;
    font-size: 15px;
}

.search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-bottom: 1px solid lightgray;
    margin: 0px 10px 8px;
    padding: 13px 0px 3px 0px
}


.search-container-focused {
    border-bottom: 1px solid var(--ik-widget-bg-color);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.search-input:focus {
    outline: none;
}