
.widget__header {
    color: var(--ik-widget-text-color);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding: 20px 10px;
    border-radius: 24px 24px 0 0;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }



  
  .widget__header__close {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }


@media (max-width: 576px) {
    .widget__header .widget__header_message_icon {
        display: none;
    }

    .widget__header {
        padding: 15px 18px;
    }
}

  

.widget__header button {
    height: 40px;
    color: var(--ik-widget-text-color-50);
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 12px;

}