.widget-min {
    cursor: pointer;
    width: 122px;
    height: 48px;
    border-radius: 30px;
    color: white;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    gap: 9px;
    position: fixed;
    right: var(--ik-widget-right);
    left: var(--ik-widget-left);
    bottom: max(var(--ik-widget-bottom-offset), 30px);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  
    transition: transform 0.5s ease-in-out 0s,
    opacity 0.5s;
  }
  
  .widget-min-circle {
    position: absolute;
    right: var(--ik-widget-right);
    left: var(--ik-widget-left);
    bottom: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    color: white;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    gap: 9px;

    transition: transform 0.5s ease-in-out 0s,
    opacity 0.5s;
  }
  
  .widget-min-hide {
      transform: translateY(calc(90px + var(--ik-widget-bottom-offset)));
      opacity: 0.5;
  }
  
  .widget-min-circle-hide {
      transform: translateY(calc(80px + var(--ik-widget-bottom-offset)));
      opacity: 0.5;
  }