
.close-button-container {
    width: 100%;
    display: flex;
}


@media (max-width: 576px) { 
    .close-button-container {
        display: none;
    }
}
