
.welcome-message-container {
    display: flex;
    width: 180px;
    position: absolute;
    padding: 20px;
    max-width: 35ch;
    min-width: 25ch;
    border-radius: 15;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: transform 0.5s ease, opacity 0.5s ease;
    line-height: 20px;
    gap: 10px;
}

.welcome-message-overlay {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ik-widget-bg-color-10);
  
}

.welcome-message-container-right:after {
    content: " ";
    position: absolute;
    right: 20px;
    bottom: -8px;
    border-top: 8px solid #F2F9FF;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: none;
  }

  .welcome-message-container-left:after {
    content: " ";
    position: absolute;
    left: 20px;
    bottom: -8px;
    border-top: 8px solid #F2F9FF;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: none;
  }

  .welcome-message-close-button {
    background-color: #E6EDF2;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }