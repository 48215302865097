.welcome-message-container {
  background-color: #fff;
  border-radius: 15px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 180px;
  min-width: 25ch;
  max-width: 35ch;
  padding: 20px;
  line-height: 20px;
  transition: transform .5s, opacity .5s;
  display: flex;
  position: absolute;
  box-shadow: 0 3px 6px #00000029;
}

.welcome-message-overlay {
  background-color: var(--ik-widget-bg-color-10);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.welcome-message-container-right:after {
  content: " ";
  border: 8px solid #0000;
  border-top-color: #f2f9ff;
  border-bottom: none;
  position: absolute;
  bottom: -8px;
  right: 20px;
}

.welcome-message-container-left:after {
  content: " ";
  border: 8px solid #0000;
  border-top-color: #f2f9ff;
  border-bottom: none;
  position: absolute;
  bottom: -8px;
  left: 20px;
}

.welcome-message-close-button {
  cursor: pointer;
  background-color: #e6edf2;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.widget-min {
  cursor: pointer;
  color: #fff;
  right: var(--ik-widget-right);
  left: var(--ik-widget-left);
  bottom: max(var(--ik-widget-bottom-offset), 30px);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: 122px;
  height: 48px;
  font-weight: bold;
  transition: transform .5s ease-in-out, opacity .5s;
  position: fixed;
  box-shadow: 0 3px 6px #00000029;
}

.widget-min-circle {
  right: var(--ik-widget-right);
  left: var(--ik-widget-left);
  cursor: pointer;
  color: #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: 60px;
  height: 60px;
  font-weight: bold;
  transition: transform .5s ease-in-out, opacity .5s;
  position: absolute;
  bottom: 30px;
  box-shadow: 0 3px 6px #00000029;
}

.widget-min-hide {
  transform: translateY(calc(90px + var(--ik-widget-bottom-offset)));
  opacity: .5;
}

.widget-min-circle-hide {
  transform: translateY(calc(80px + var(--ik-widget-bottom-offset)));
  opacity: .5;
}

.message-list {
  flex-direction: column;
  gap: 13px;
  padding: 15px;
  display: flex;
  overflow: scroll;
}

.button-container {
  margin-top: auto;
}

.widget-container {
  background-color: #fff;
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
  overflow: scroll;
}

.widget-hide {
  opacity: .5;
  transform: translateY(1800px);
}

.widget {
  right: var(--ik-widget-right);
  left: var(--ik-widget-left);
  height: calc(95vh - var(--ik-widget-bottom-offset));
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  max-height: 700px;
  transition: transform .5s ease-in-out, opacity .5s;
  display: flex;
  position: fixed;
  bottom: 30px;
}

.widget form {
  margin-bottom: 0;
}

.widget label, .widget input, .widget textarea {
  width: 100%;
  font-size: 14px;
  display: block;
}

.widget label {
  margin-bottom: 5px;
  font-weight: bold;
}

.widget input, .widget button, .widget select, .widget textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  line-height: 1.5;
}

.widget input, .widget select {
  margin-bottom: 10px;
}

.widget textarea {
  resize: none;
  margin: 10px 0;
}

.widget .phoneInput {
  width: 100%;
}

@media (width <= 576px) {
  .widget {
    top: 5vh;
    bottom: unset;
    right: unset;
    width: 90vw;
    height: 90vh;
    left: 5vw;
  }
}

.widget__submit {
  justify-content: center;
  display: flex;
}

.widget__submit__button {
  cursor: pointer;
  color: #fff;
  background-color: var(--ik-widget-bg-color);
  justify-content: center;
  align-items: center;
  width: 230px;
  margin-top: 28px;
  margin-bottom: 0;
  font-size: 14px;
  display: flex;
}

.widget__spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--ik-widget-bg-color);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 2px 5px;
  animation: 2s linear infinite spin;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.success {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 20px;
  display: flex;
}

.loader {
  border: 2px solid #0000001a;
  border-top-color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: 1s linear infinite spin;
}

.error {
  color: #8b0000;
  text-align: center;
  padding: 0 25px;
  font-size: 14px;
}

.widget__header {
  color: var(--ik-widget-text-color);
  text-align: center;
  border-radius: 24px 24px 0 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  position: relative;
}

.widget__header__close {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (width <= 576px) {
  .widget__header .widget__header_message_icon {
    display: none;
  }

  .widget__header {
    padding: 15px 18px;
  }
}

.widget__header button {
  color: var(--ik-widget-text-color-50);
  cursor: pointer;
  background: none;
  border: none;
  height: 40px;
  position: absolute;
  right: 12px;
}

.close-button-container {
  width: 100%;
  display: flex;
}

@media (width <= 576px) {
  .close-button-container {
    display: none;
  }
}

.location-list {
  background-color: #fff;
  border-radius: 19px;
  min-height: 150px;
  margin: 12px;
}

.list-item {
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #d3d3d3;
  flex-direction: row;
  gap: 8px;
  padding: 12px 10px 16px;
  display: flex;
}

.list-item-end {
  border-bottom: none;
  border-radius: 10px;
}

.list-item:hover {
  background: #f3f3f3;
}

.list-item .title {
  margin-bottom: 6px;
}

.list-item .address {
  color: gray;
  flex-direction: column;
  font-size: 15px;
  font-weight: lighter;
  line-height: 15px;
  display: flex;
}

.search-container {
  border-bottom: 1px solid #d3d3d3;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0 10px 8px;
  padding: 13px 0 3px;
  display: flex;
}

.search-container-focused {
  border-bottom: 1px solid var(--ik-widget-bg-color);
  -o-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
}

.search-input:focus {
  outline: none;
}

html {
  font-family: Inter_400, sans-serif;
}

body {
  margin: 0;
}

/*# sourceMappingURL=index.84575184.css.map */
